import { SchemaData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../components/View';
import Form from '../../../../containers/Admin/Form';
import IconDatabase from '../../../../icons/Database';
import params from '../../../../params/schema.json';
import TemplateAdmin from '../../../../templates/admin';
import requireUser from '../../../../utils/requireUser';

const PageSchemasUpdate: FC<PageProps> = props => {
  const {
    params: { schemaId },
  } = props;

  const model = new SchemaData({ params });

  return (
    <TemplateAdmin>
      <CMSView>
        <Form
          {...props}
          docId={schemaId}
          itemPathnamePrefix="/admin/schemas/"
          menu={{
            right: [
              {
                color: 'neutral',
                iconClassName: 'transform rotate-180',
                iconLeft: IconDatabase,
                size: 'md',
                square: true,
                to: `/admin/schemas/${schemaId}/list/`,
                variant: 'outline',
              },
            ],
          }}
          model={model}
          name="schema"
          type="update"
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(PageSchemasUpdate, 'admin');
